import { createContext, useEffect } from "react";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import LibraryDrawer from "../components/asset-library/LibraryDrawer";
import Menu from "../components/toolbar/Menu";
import SceneDrawer from "../components/scene/SceneDrawer";
import WebGLCanvas, { TreeNode } from "../components/WebGLCanvas";

import { Alert, PaletteMode, Snackbar, useTheme } from "@mui/material";

import { Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useParams } from "react-router-dom";
import { User } from "../utils/user";
import { Spinner } from "../components/Spinner";

import { SelectedNodesProvider } from '../components/SelectedNodesContext';
import { MaterialProvider } from '../components/MaterialsContext';
import { DragItemProvider } from '../components/DragItemContext';
import { SceneTabModeProvider, SceneTabMode } from "../components/SceneTabModeContext";
const appbarHeight = isMobile ? 74.5 : 49.5;

const defaultDrawerWidth = 350;
const initialDrawerWidth = isMobile ? 0 : defaultDrawerWidth;

export type Access = "can view" | "can edit" | "no access";
export interface SceneMeta {
    realPath: string,
    name: string,
    access: Access,
}

export type WsConnectionStatus = "not yet connected" | "connected" | "disconnected";

export const LatestRenderContext = createContext<number>(0);

export type FigurementAppProps = { user: User, canEdit: boolean, onThemeModeChange: (mode: PaletteMode) => void };

export default function FigurementApp({ user, canEdit, onThemeModeChange }: FigurementAppProps) {
    const { id: sceneId } = useParams()
    const [newRenderCounter, setNewRenderCounter] = useState<number>(0);
    const [newChatMessagesCounter, setNewChatMessagesCounter] = useState<number>(0);

    const [menuIconCommand, setMenuIconCommand] = useState<string>();
    const [centerAndFit, setCenterAndFit] = useState(false);
    const [renderMode, setRenderMode] = useState<string>();
    const [isWsConnected, setIsWsConnected] = useState<WsConnectionStatus>("not yet connected");

    const handle = useFullScreenHandle();

    const [sceneDrawerWidth, setSceneDrawerWidth] =
        useState(initialDrawerWidth);
    const [libraryDrawerWidth, setLibraryDrawerWidth] =
        useState(initialDrawerWidth);
    const [showSceneDrawer, setShowSceneDrawer] = useState(
        initialDrawerWidth != 0
    );
    const [showLibraryDrawer, setShowLibraryDrawer] = useState(
        initialDrawerWidth != 0
    );

    const [sceneTree, setSceneTree] = useState<TreeNode>();
    const [_cameraList, setCameraList] = useState();

    const [materialTypes, setMaterialTypes] = useState([]);

    const [activeUsersList, setActiveUsersList] = useState();
    const [filename, setFilename] = useState("");

    const [screenHeight, setScreenHeight] = useState(0);
    const [_screenWidth, setScreenWidth] = useState(0);

    const [breakPoint, setBreakPoint] = useState("");

    const [currentlyDraggedMaterial, _setCurrentlyDraggedMaterial] = useState();

    const [latestRender, setLatestRender] = useState<number>(Date.now())

    const [loadingMessage, setLoadingMessage] = useState<string>("Loading app...");

    const setSceneTreeAndSceneRoute = (tree: TreeNode) => {
        setSceneTree(tree);
    };

    const setLibraryDrawerVisibility = (visible: boolean) => {
        // is this one called on mobile?
        if (!visible) {
            setLibraryDrawerWidth(0);
        } else {
            if (breakPoint == "sm" || breakPoint == "xs") {
                setSceneDrawerVisibility(false);
            }
            setLibraryDrawerWidth(defaultDrawerWidth);
        }
        setShowLibraryDrawer(visible);
    };

    const onRenderClick = async () => {
        await saveRenderAndThumbnail();
    }

    const setSceneDrawerVisibility = (visible: boolean) => {
        // is this one called on mobile?
        if (!visible) {
            setSceneDrawerWidth(0);
        } else {
            if (breakPoint == "sm" || breakPoint == "xs") {
                setLibraryDrawerVisibility(false);
            }
            setSceneDrawerWidth(defaultDrawerWidth);
        }
        setShowSceneDrawer(visible);
    };

    const theme = useTheme();

    const handleResize = () => {
        let height = 0;
        let width = 0;

        if (isMobile) {
            // && CSS.supports("height: 100dvh")
            //`calc(100dvh - ${appbarHeight}px)`;
            width = document.documentElement.clientWidth;
            height = document.documentElement.clientHeight;
        } else {
            //`calc(100vh - ${appbarHeight}px)`;
            width = window.innerWidth;
            height = window.innerHeight;
        }

        setScreenHeight(height - appbarHeight + 1);
        setScreenWidth(width);

        let b = "";

        if (width <= theme.breakpoints.values.sm) {
            b = "xs";
        } else if (width <= theme.breakpoints.values.md) {
            b = "sm";
        } else {
            b = "md";
        }
        setBreakPoint(b);

        if (showLibraryDrawer && showSceneDrawer && (b == "sm" || b == "xs")) {
            setLibraryDrawerVisibility(false);
        }
    };

    const handleFilenameChange = (name: string) => {
        if (name !== filename) {
            setFilename(name);
            window.lys.setSceneName(name);
        }
    }

    useEffect(() => {
        console.debug("FigurementApp mount");
        handleResize();
        window.addEventListener("resize", handleResize);
        globalThis.setLoadingMessage = (message: string) => { setLoadingMessage(message) };

        return () => {

            window.removeEventListener("resize", handleResize);
            console.debug("FigurementApp unmount");
        }; 

    }, []);

    async function saveRenderAndThumbnail() {
        const renderFrame = await window.lys.getCurrentImage();

        if (!renderFrame) {
            console.warn("No pixels in render buffer. Not saving render.")
            return;
        }

        const form = new FormData();
        form.set("full", renderFrame.full);
        form.set("thumbnail", renderFrame.thumbnail);

        fetch("/api/scenes/" + sceneId + "/renders", {
            method: "POST",
            body: form,
        })
            .then((response) => response.text())
            .then(() => {
                // Increment the render counter on successful save
                setNewRenderCounter((n) => n + 1);
                setLatestRender(Date.now());
            })
            .catch((error) => {
                console.error("Failed to save render:", error);
            });
    }

    async function saveThumbnail(sceneId: string): Promise<Response> {
        if (!window.lys) return; // Perhaps lys has already been unloaded by reloading the page

        const renderFrame = await window.lys.getCurrentImage();
        if (!renderFrame) {
            console.warn("No pixels in render buffer. Not saving thumbnail.")
            return;
        }

        console.debug("Took thumbnail image")
        const form = new FormData();
        form.set("thumbnail", renderFrame.thumbnail);

        return fetch("/api/scenes/" + sceneId + "/renders", {
            method: "POST",
            body: form,
        })
    }

    return (
        <FullScreen handle={handle}>
            <SceneTabModeProvider initialMode={canEdit ? SceneTabMode.scene : SceneTabMode.chat}>
                <DragItemProvider>
                    <Menu
                        onFullScreen={handle}
                        isfullScreen={handle.active}
                        onIconClick={setMenuIconCommand}
                        onCenterAndFitClick={setCenterAndFit}
                        onRenderClick={onRenderClick}
                        onShowLibraryDrawer={setLibraryDrawerVisibility}
                        onShowSceneDrawer={setSceneDrawerVisibility}
                        libraryDrawerOpen={showLibraryDrawer}
                        sceneDrawerOpen={showSceneDrawer}
                        user={user}
                        sceneId={sceneId}
                        activeUsersList={activeUsersList}
                        sceneName={filename}
                        onChangeSceneName={handleFilenameChange}
                        canEdit={canEdit}
                        onThemeModeChange={onThemeModeChange}
                        onRenderModeClick={setRenderMode}
                    />

                    {!isMobileOnly && <Toolbar variant="dense" />}
                    <LatestRenderContext.Provider value={latestRender}>
                        <Grid
                            container
                            sx={{ width: "100%", height: "100%", maxHeight: "100%" }}
                        >
                            {/*
xs is mobile portrait
sm is mobile landscape
md is desktop */}

                            <Grid xs={12} sm={libraryDrawerWidth > 0 ? 6 : 12} md={"auto"}>
                                {showLibraryDrawer && canEdit && (
                                    <LibraryDrawer
                                        height={screenHeight}
                                        drawerWidth={breakPoint == "md"
                                            ? libraryDrawerWidth
                                            : undefined}
                                        onDrawerWidthChange={setLibraryDrawerWidth}
                                        materialList={[]} />
                                )}
                            </Grid>

                            {/*
xs is mobile portrait
sm is mobile landscape
md is desktop */}
                            <Grid
                                xs={12}
                                sm={sceneDrawerWidth > 0 || libraryDrawerWidth > 0 ? (canEdit ? 6 : 12) : 12}
                                md={true}
                            >
                                {/* breakPoint=="md" || breakPoint=="sm" ? screenHeight : sceneDrawerWidth>0 || libraryDrawerWidth>0 ? screenWidth*(3/4) : screenHeight */}
                                <Box
                                    height={
                                        breakPoint == "md" || breakPoint == "sm"
                                            ? screenHeight
                                            : (((sceneDrawerWidth > 0 || libraryDrawerWidth > 0) && canEdit)
                                                ? screenHeight * 0.5
                                                : screenHeight)
                                    }
                                    sx={{ overflow: "hidden", position: 'relative'  }}
                                >
                                    {loadingMessage && (<Spinner description={loadingMessage} />)}
                                    <WebGLCanvas
                                        mouseMode={menuIconCommand}
                                        centerAndFit={centerAndFit}
                                        onCenterAndFitDone={setCenterAndFit}
                                        renderMode={renderMode}
                                        sceneId={sceneId}
                                        user={user}
                                        onLoadMessage={setLoadingMessage}
                                        onSceenTreeChange={setSceneTreeAndSceneRoute}
                                        onSceneNameUpdate={setFilename}
                                        onActiveUsersListChange={setActiveUsersList}
                                        onCameraListChange={setCameraList}
                                        setMaterialTypes={setMaterialTypes}
                                        currentlyDraggedMaterial={currentlyDraggedMaterial}
                                        onUpdateSceneThumbnail={() => setTimeout(async () => {
                                            await saveThumbnail(sceneId);
                                            setLatestRender(Date.now());
                                        }, 500)}
                                        onConnectionChanged={(connected) => setIsWsConnected(connected ? "connected" : "disconnected")}
                                    />
                                    {isWsConnected === "disconnected" && (
                                        <Snackbar open={true} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} >
                                            <Alert severity="warning" color="warning">
                                                We are having issues connecting to the server. Changes will be saved as soon as network is restored.
                                            </Alert>
                                        </Snackbar>)}
                                </Box>
                            </Grid>
                            {/*
xs is mobile portrait
sm is mobile landscape
md is desktop */}
                            <Grid xs={12} sm={6} md={"auto"}>
                                {showSceneDrawer && (
                                    <MaterialProvider>
                                        <SelectedNodesProvider>
                                            <SceneDrawer
                                                user={user}
                                                canEdit={canEdit}
                                                height={
                                                    breakPoint == "xs"
                                                        ? screenHeight * 0.5
                                                        : screenHeight
                                                }
                                                sceneTree={sceneTree}
                                                drawerWidth={
                                                    breakPoint == "md"
                                                        ? sceneDrawerWidth
                                                        : undefined
                                                }
                                                materialTypes={materialTypes}
                                                sceneId={sceneId}
                                                newRenderCounter={newRenderCounter}
                                                setNewRenderCounter={setNewRenderCounter}
                                                newChatMessagesCounter={newChatMessagesCounter}
                                                setNewChatMessagesCounter={setNewChatMessagesCounter}
                                            />
                                        </SelectedNodesProvider>
                                    </MaterialProvider>
                                )}
                            </Grid>
                        </Grid>
                    </LatestRenderContext.Provider>
                    {isMobileOnly && <Toolbar variant="dense" />}
                </DragItemProvider>
            </SceneTabModeProvider>
        </FullScreen>
    );
}
