import React, { useState, useEffect } from 'react';
import { SimpleTreeView } from '@mui/x-tree-view';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Box } from '@mui/material';
import { useDragItemContext } from '../DragItemContext';
import CameraIcon from '@mui/icons-material/CameraAlt';

// Define Camera interface
interface Camera {
  id: number;
  name: string;
}

const CameraList = () => {
  const { setDraggedItem } = useDragItemContext();
  const [selectedCamera, setSelectedCamera] = useState<number>();
  const [cameras, setCameras] = useState<Camera[]>([]);
  const [forceUpdate, setForceUpdate] = useState(0);

  const handleDragStart = (camera: Camera, event: React.DragEvent) => {
    setDraggedItem(camera, "cameraList");
  };

  const handleNodesSelected = (event: React.SyntheticEvent, itemIds: string[] | string) => {
    const selectedId = Array.isArray(itemIds) ? Number(itemIds[0]) : Number(itemIds);

    if (!isNaN(selectedId)) {
      globalThis.lys.setActiveCamera(selectedId, true);
      setSelectedCamera(selectedId);
    }
  };

  useEffect(() => {
    globalThis.updateCameras = () => setForceUpdate((n) => n + 1);
    updateCameraList();

    return () => {
      delete globalThis.updateCameras;
    };
  }, []);

  const updateCameraList = () => {
    if (!globalThis.lys) return;

    const cameraIds: number[] = globalThis.lys.getAllCameraIds();
    

    

    // Fetch camera names
    const newCameras: Camera[] = cameraIds.map((id) => {
      const cameraNode = globalThis.lys.getNodeById(id);
      const name = cameraNode?.getName(); // Assuming there's a method to get the camera's name

      return {
        id,
        name,
      };
    });

    setSelectedCamera(globalThis.lys.getActiveCameraId());

    setCameras(newCameras);
  };

  useEffect(() => {
    updateCameraList();
  }, [forceUpdate]);

  return (
    <div>
      <SimpleTreeView
        selectedItems={selectedCamera ? [selectedCamera.toString()] : []}
        onSelectedItemsChange={handleNodesSelected}
      >
        {cameras.map((camera: Camera) => (
          <TreeItem
            draggable={true}
            onDragStart={(e) => handleDragStart(camera, e)}
            key={camera.id}
            itemId={camera.id.toString()}

            slots={{
                endIcon: CameraIcon,
              }}

            label={
              <div
                style={{
                  padding: '4px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {/* Camera Name */}
                <span style={{ flexGrow: 1 }}>
                  {camera.name}
                </span>
              </div>
            }
          />
        ))}
      </SimpleTreeView>
    </div>
  );
};

export default CameraList;
