import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


const PartRightClickMenu = ({ location, handleClose }) => {


    const handleClone = () => {
        const nodeIds : number[] = globalThis.lys.getSelectedNodeIds();
        const newIds : number[] = [];
    
        nodeIds.forEach((id) => {
          const newId : number = globalThis.lys.cloneSubtree(id,true);
          newIds.push(newId);
        });
    
        globalThis.lys.setSelectedNodeIds(newIds);
        handleClose();
    };

    const handleDelete = () => {
        const nodeIds : number[] = globalThis.lys.getSelectedNodeIds();
        const newIds : number[] = [];
    
        nodeIds.forEach((id) => {
          const newId : number = globalThis.lys.deleteNode(id,true);
          newIds.push(newId);
        });
    
        globalThis.lys.setSelectedNodeIds([]);
        handleClose();
    };

    const handleGroup = () => {
        
        const selectedNodeIds : number[] = globalThis.lys.getSelectedNodeIds();
        const newIds : number[] = [];

        const newId : number = globalThis.lys.createNodeGenId("Group");
        const parentId : number  = globalThis.lys.getNodeById(selectedNodeIds[0]).getParent().getId();

        globalThis.lys.reparentNode(parentId,newId,true);

        selectedNodeIds.forEach((id) => {
            globalThis.lys.reparentNode(newId,id,true);
        });
        globalThis.lys.setSelectedNodeIds([newId]);

        handleClose();
    };

    // TODO: Unlink all material ass individual (but without dangling materials)
    const handleUnlinkMaterial = () => {
        
        const selectedNodeIds : number[] = globalThis.lys.getSelectedNodeIds();
        const newIds : number[] = [];
        
        selectedNodeIds.forEach((id) => {
            const jsonMat : string  = globalThis.lys.getNodeById(selectedNodeIds[0]).getMaterial().toJSONString()
            const newMatId = globalThis.lys.createMaterialFromJson(jsonMat);

            globalThis.lys.setMaterial(id,newMatId,true);
        });

        handleClose();
    };

    const handleLinkMaterial = () => {
        
        const selectedNodeIds : number[] = globalThis.lys.getSelectedNodeIds();
        const newIds : number[] = [];

         const matId : string  = globalThis.lys.getNodeById(selectedNodeIds[0]).getMaterial().getId();

        selectedNodeIds.forEach((id) => {
            globalThis.lys.setMaterial(id,matId,true);
        });

        handleClose();
    };

    return (
        <Menu
            open={location != null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={{ top: location ? location[1] :0, left: location ? location[0] : 0 }
            }
        >
            <MenuItem onClick={handleClone}>Clone Object(s)</MenuItem>
            <MenuItem onClick={handleDelete}>Delete Object(s)</MenuItem>
            <MenuItem onClick={handleGroup}>Group</MenuItem>
            <MenuItem onClick={handleUnlinkMaterial}>Separate Material(s)</MenuItem>
            <MenuItem onClick={handleLinkMaterial}>Merge Material(s)</MenuItem>
        </Menu>
    );
};

export default PartRightClickMenu;