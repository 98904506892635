import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';

export type SpinnerProps = {
  description?: string;
};

export const Spinner = ({ description }: SpinnerProps) => {
  const [showDescription, setShowDescription] = useState(!!description);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined;

    console.log('message description', description);

    if (description) {
      setShowDescription(true);
      timer = setTimeout(() => {
        setShowDescription(false);
      }, 1000); // Hide after 1 second
    } else {
      setShowDescription(false);
    }

    // Cleanup the timer if the component unmounts or description changes
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [description]);

  const handleMouseEnter = () => {
    if (description) {
      setShowDescription(true);
    }
  };

  const handleMouseLeave = () => {
    if (description) {
      setShowDescription(false);
    }
  };

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'transparent',
        padding: '8px',
        borderRadius: '4px',
        overflow: 'hidden',
        width: showDescription ? '200px' : '40px',
        transition: 'width 0.3s ease',
        zIndex: 1,
      }}
    >
      {/* CircularProgress is always visible */}
      <CircularProgress size={24} />
      {description && (
        <Box
          sx={{
            marginLeft: '8px',
            whiteSpace: 'nowrap',
            transition: 'opacity 0.3s ease',
            opacity: showDescription ? 1 : 0,
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: '50px',
          }}
        >
          {description}
        </Box>
      )}
    </Box>
  );
};
