import { Dialog, DialogContent, DialogTitle, Orientation, Paper, SxProps, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ResizableContainer from "../ResizableContainer";

import { GridCloseIcon, GridDeleteIcon } from "@mui/x-data-grid";
import { useRef } from "react";
import { useComponentSize } from "../../utils/use-component-size";
import FolderTree from "../asset-library/FolderTree";
import * as FileApi from "../../services/file-api"
import FileGallery from "./FileGrid";
import { LatestRenderContext } from "../../pages/FigurementApp";
import { Download, WidgetsOutlined } from "@mui/icons-material";
import { AskDeleteDialog } from "./AskDeleteDialog";

export default function AssetManager({
    showFolders,
    recursive,
    filetypes,
    selectedPath,
    onSelectedPathChange,
    sx,
}: {
    showFolders: boolean;
    recursive: boolean;
    filetypes: string;
    selectedPath: string,
    onSelectedPathChange?: (path: string) => void;
    sx?: SxProps<Theme>;
}) {
    const [askDeleteRender, setAskDeleteRender] = useState<boolean>(false);
    const [displayedRender, setDisplayedRender] = useState<FileApi.FSEntry | null>(null);

    const navigate = useNavigate();

    const handleClickOnThumbnail = (e: React.MouseEvent, file: FileApi.FSEntry) => {
        e.stopPropagation();
        e.preventDefault();
        if (file.type === "scene") {
            navigate(`/scenes/${file.id}/edit`);
        } else if (file.type === "render") {
            setDisplayedRender(file);
        }
    };

    const componentRef = useRef(null);
    const { width: componentWidth } = useComponentSize(componentRef);
    const [orientation, setOrientation] = useState<Orientation>("vertical");

    useEffect(() => {
        if (componentWidth > 600) setOrientation("horizontal");
        else setOrientation("vertical");
    }, [componentWidth]);
    const latestRenderDate = useContext(LatestRenderContext);

    const [folderTree, setFolderTree] = useState([]);

    useEffect(() => { FileApi.loadFolders().then(setFolderTree) }, []);
    useEffect(() => {
        FileApi.loadFilesForFolder(selectedPath, recursive, filetypes, showFolders)
            .then((jsonData) => {
                if (showFolders) {
                    setFileList(jsonData.filter((obj: FileApi.FSEntry) => obj.type !== "folder"));
                    setFolderList(jsonData.filter((obj: FileApi.FSEntry) => obj.type === "folder"));
                } else {
                    setFileList(jsonData);
                }
            })
    }, [showFolders, latestRenderDate]); 

    const [fileList, setFileList] = useState<FileApi.FSEntry[]>([]);
    const [folderList, setFolderList] = useState<FileApi.FSEntry[]>([]);

    const clickOnFolderIcon = async (id: string) => {
        onSelectNode(id);
        navigate(`/files/${id}`, { replace: true });
    };

    const onSelectNode = async (id: string): Promise<void> => {
        FileApi.loadFilesForFolder(id, recursive, filetypes, showFolders)
            .then((jsonData) => {
                if (showFolders) {
                    let files = jsonData.filter((obj: FileApi.FSEntry) => obj.type !== "folder");
                    setFileList(files);
                    setFolderList(jsonData.filter((obj: FileApi.FSEntry) => obj.type === "folder"));
                } else {
                    setFileList(jsonData);
                }
            });
        if (!!onSelectedPathChange) { onSelectedPathChange(id); }
    };

    function confirmedDeleteScene(id: string) {
        fetch(`/api/scenes/${id}`, {
            method: "DELETE",
        }).then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        }).then(() => {
            onSelectNode(selectedPath);
        })
    }

    function confirmedDeleteRender(id: string) {
        fetch(id, {
            method: "DELETE",
        }).then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setAskDeleteRender(null)
            setDisplayedRender(null);
        }).then(() => {
            onSelectNode(selectedPath);
        })
    }

    function downloadRender(file: FileApi.FSEntry) {
        const url = new URL(file.id, window.location.href);
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "render.png";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
    }

    function handleGotoScene(file: FileApi.FSEntry): void {
        console.debug({ renderDisplay: file })
        if (file.sceneId) { 
            navigate(`/scenes/${file.sceneId}/edit`); 
            setDisplayedRender(null);
        }
    }

    return (
        <>
            <Dialog fullScreen open={!!displayedRender} onClose={() => setDisplayedRender(null)} >
                <DialogTitle>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setDisplayedRender(null)}
                        aria-label="close"
                        title="Close"
                    >
                        <GridCloseIcon />
                    </IconButton>
                    <IconButton 
                        onClick={() => downloadRender(displayedRender)}
                        title="Download"
                        aria-label="download render">
                        <Download /></IconButton>
                    <IconButton 
                        onClick={() => handleGotoScene(displayedRender)} 
                        size="small" 
                        title="Go to scene" 
                        aria-label="Go to scene">
                        <WidgetsOutlined />
                    </IconButton>
                    <IconButton 
                        onClick={() => setAskDeleteRender(true)} 
                        size="small" 
                        title="Delete render" 
                        aria-label="Delete render">
                        <GridDeleteIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <img src={displayedRender?.id} />
                </DialogContent>
            </Dialog >            
            <AskDeleteDialog noun="Render" open={askDeleteRender} onCancel={() => setAskDeleteRender(null)} onConfirm={() => confirmedDeleteRender(displayedRender.id)} onClose={() => setAskDeleteRender(null)} />
            <Paper
                ref={componentRef}
                square={true}
                sx={{ height: "100%", ...sx }}
                style={{ display: "flex", flexGrow: 1, overflow: "hidden" }}
            >
                <Stack
                    direction="column"
                    sx={{ height: "100%" }}
                    style={{ display: "flex", flexGrow: 1 }}
                >
                    <Stack
                        direction="row"
                        sx={{ flexGrow: 1, overflow: "hidden" }}
                    >
                        <ResizableContainer
                            defaultSizes={[0.3, 0.7]}
                            orientation={orientation}
                        >
                            <FolderTree 
                                folderTree={folderTree} 
                                selectedPath={selectedPath} 
                                onSelectNode={onSelectNode} 
                            />
                            <Box sx={{ padding: 1, overflow: "auto" }} >
                                <FileGallery
                                    folderList={folderList}
                                    fileList={fileList}
                                    onSelectFolder={clickOnFolderIcon}
                                    onSelectFile={handleClickOnThumbnail}
                                    onDeleteScene={confirmedDeleteScene}
                                    onDeleteRender={confirmedDeleteRender}
                                />
                            </Box>
                        </ResizableContainer>
                    </Stack>
                </Stack>
            </Paper>
        </>
    );
}
