// import ModelIcon from "@mui/icons-material/Chair";
import SceneIcon from "@mui/icons-material/WidgetsOutlined";
// import ImageIcon from "@mui/icons-material/Image";
// import EnvironmentIcon from "@mui/icons-material/Language";
// import LightIcon from "@mui/icons-material/Lightbulb";
// import ColorIcon from "@mui/icons-material/Palette";
import MaterialIcon from "@mui/icons-material/SportsSoccer";
import { Paper, Tab, Tabs } from "@mui/material";
import Stack from '@mui/material/Stack';
import { useCallback, useEffect, useState } from "react";
import AssetManager from "./AssetManager";
import { RELATIVE_RENDERS_PATH, RELATIVE_SCENES_PATH, RELATIVE_MATERIALS_PATH } from "../../App";
import { CameraAlt } from "@mui/icons-material";

const minDrawerWidth = 200;
const maxDrawerWidth = 1000;

export enum Mode {
    scenes,
    models,
    materials,
    colors,
    textures,
    envs,
    lights,
    renders,
}

const tabFileType: Record<Mode, string> = {
    [Mode.scenes]: ".scene,.share.toml",
    [Mode.models]: "",
    [Mode.materials]: ".mat",
    [Mode.colors]: ".color",
    [Mode.textures]: ".png,.jpg,.jpeg,.tga",
    [Mode.envs]: ".environment",
    [Mode.lights]: ".light",
    [Mode.renders]: ".jpg",
}

export type LibraryDrawerProps = {
    drawerWidth: number,
    onDrawerWidthChange: (width: number) => void,
    height: number,
    materialList: string[],
}

export default function LibraryDrawer({
    drawerWidth,
    onDrawerWidthChange,
    height,
    materialList,
}: LibraryDrawerProps) {
    const [selectedTab, setSelectedTab] = useState<Mode>(Mode.scenes);
    const [filetypes, setFiletypes] = useState(tabFileType[selectedTab]);

    const handleTabSelect = (_event: any, newSelectedTab: number) => {
        setFiletypes(tabFileType[newSelectedTab]);
        setSelectedTab(newSelectedTab);
    };

    const handleMouseDown = (_e: any) => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback((e: MouseEvent) => {
        window.getSelection().removeAllRanges();
        const newWidth = e.clientX - document.body.offsetLeft;
        onDrawerWidthChange(newWidth);
        if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
            onDrawerWidthChange(newWidth);
        }
    }, []);

    return (
        <Paper square={true} >
            <Stack
                width={drawerWidth}
                minWidth={350}
                height={height}
                maxHeight={height}
                minHeight={height}
                alignItems="stretch"
                justifyContent="space-between"
                direction="row"
            >
                <Stack sx={{ marginRight: "-3px", width: "100%" }}>
                    <Tabs
                        variant="fullWidth"
                        value={selectedTab}
                        onChange={handleTabSelect}
                        aria-label="icon tabs example"
                    >
                        <Tab
                            icon={<SceneIcon />}
                            aria-label="scenes"
                            label="Scenes"
                            value={Mode.scenes}
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
                        {/*                        <Tab
                            icon={<ModelIcon />}
                            aria-label="models"
                            label="Models"
                            value={Mode.models}
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
*/}                        <Tab
                            icon={<MaterialIcon />}
                            aria-label="materials"
                            label="Materials"
                            value={Mode.materials}
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
                        {/*                        <Tab
                            icon={<ColorIcon />}
                            aria-label="colors"
                            label="Colors"
                            value={Mode.colors}
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
                        <Tab
                            icon={<ImageIcon />}
                            aria-label="images"
                            label="Textures"
                            value={Mode.textures}
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
                        <Tab
                            icon={<EnvironmentIcon />}
                            aria-label="environments"
                            label="Envs"
                            value={Mode.envs}
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
                        <Tab
                            icon={<LightIcon />}
                            aria-label="lights"
                            label="Lights"
                            value={Mode.lights}
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
*/}                        <Tab
                            icon={<CameraAlt />}
                            aria-label="renders"
                            label="Renders"
                            value={Mode.renders}
                            sx={{ minWidth: 50, textTransform: 'none', }}
                        />
                    </Tabs>
                    {selectedTab === Mode.scenes &&
                        <AssetManager
                            showFolders={false}
                            recursive={true}
                            filetypes={filetypes}
                            selectedPath={RELATIVE_SCENES_PATH} />
                    }
                    {selectedTab === Mode.materials &&
                        <AssetManager
                            showFolders={false}
                            recursive={true}
                            filetypes={filetypes}
                            selectedPath={RELATIVE_MATERIALS_PATH} />
                    }
                    {selectedTab === Mode.renders &&
                        <AssetManager
                            showFolders={false}
                            recursive={true}
                            filetypes={filetypes}
                            selectedPath={RELATIVE_RENDERS_PATH} />
                    }
                </Stack>
                <Stack onMouseDown={e => handleMouseDown(e)} sx={{ width: "3px", cursor: "ew-resize" }} ></Stack>
            </Stack>
        </Paper>
    )
}
