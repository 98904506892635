import CubeIcon from '@mui-extra/icons/CubeOutlineIcon';
import BoxPlusIcon from '@mui/icons-material/AddBoxOutlined';
import CameraIcon from '@mui/icons-material/CameraAltOutlined';
import BoxMinusIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { SimpleTreeView } from '@mui/x-tree-view';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { DragEvent, useEffect, useState } from "react";
import SceneTreeElement from './SceneTreeElement';
import { useTheme } from '@mui/material/styles';
import { TreeNode, TreeNodeId } from '../WebGLCanvas';
import { useSelectedNodes } from '../SelectedNodesContext';


const nodeTypeToIcon = (typeName: string) => {
    switch (typeName) {
        case "node":
            return undefined;
        case "camera":
            return CameraIcon;
        case "trimesh":
            return CubeIcon;
        default:
            return undefined;
    }
}
 
export type SceneTreeProps = {
    sceneTree: TreeNode,
}

export default function SceneTree({ sceneTree }: SceneTreeProps) {
    const [expandedNodes, setExpandedNodes] = useState<TreeNodeId[]>([]);
    const [draggedItemIds, setDraggedItemIds] = useState<TreeNodeId[]>([]);
    const [draggedOverItem, setDraggedOverItem] = useState<TreeNode | null>(null);
    const { selectedNodeIds, setSelectedNodeIds } = useSelectedNodes();

    const [selectedNodes, onNodesSelected] = useState<TreeNodeId [] | null>(null)
        
    // Function to sync selectedNodeIds (number[]) with selectedNodes (string[])
    useEffect(() => {
        if (selectedNodes !== null) {
            const numericIds = selectedNodes.map((node) => Number(node)).filter((id) => !isNaN(id));

            // Only update if there is a difference in values
            const areEqual = numericIds.length === selectedNodeIds.length && numericIds.every((id, index) => id === selectedNodeIds[index]);
            if (!areEqual) {
                setSelectedNodeIds(numericIds);
            }
        }
    }, [selectedNodes]);

    // Function to sync selectedNodes (string[]) with selectedNodeIds (number[])
    useEffect(() => {
        if (selectedNodeIds !== null) {
            const stringIds = selectedNodeIds.map((id) => id.toString());

            // Only update if there is a difference in values
            const areEqual = stringIds.length === (selectedNodes?.length || 0) && stringIds.every((id, index) => id === selectedNodes?.[index]);
            if (!areEqual) {
                onNodesSelected(stringIds);
            }
        }
    }, [selectedNodeIds]);

    useEffect(() => {
        if (selectedNodeIds.length > 0) {
            const initialStringIds = selectedNodeIds.map((id) => id.toString());
            onNodesSelected(initialStringIds);
        }
    }, []); // Run once on mount
    

    const theme = useTheme()

    const handleNodeClick = (id: TreeNodeId) => {
        const newArray = [...new Set([...selectedNodes, id])];
        onNodesSelected(newArray);  
    }

    const dragStyle = (node: TreeNode) => {
        if (!node || !draggedOverItem) return
        return draggedOverItem?.id == node?.id 
            ? { 
                outlineColor: theme.palette.primary.main, 
                outlineWidth: "2px", 
                outlineStyle: "solid", 
                outlineOffset: "-2px" 
            }
            : draggedItemIds?.some(id => id == node?.id)
                ? { opacity: "0.5" }
                : undefined
    }
  
    const renderTree = (node: TreeNode) => {

        return (
   

            <TreeItem
                sx={{ ...dragStyle(node), p: 0 }}
                itemId={node.id}
                key={node.id}
                slots={{
                    endIcon: nodeTypeToIcon(node.type)
                }}
                draggable={true}
                onDragStart={e => handleDragStart(e, node)}
                onDragOver={e => handleDragOver(e, node)}
                onDragLeave={e => handleDragLeave(e, node)}
                onDragEnd={handleDragEnd}
                onDrop={handleDrop}
                label={<SceneTreeElement node={node} onClick={() => handleNodeClick(node.id)} />}
            >
                {Array.isArray(node.children)
                    ? node.children.map((n) => renderTree(n))
                    : null}
            </TreeItem>
        );
    };
   

    const handleNodesSelected = (_event: React.SyntheticEvent, itemIds: TreeNodeId[]) => {
        onNodesSelected(itemIds);
    }

    const findParentIdsForMultipleTargets = (nodes: TreeNode[], targetIds: TreeNodeId[]): TreeNodeId[] => {
        // Set to store unique parent IDs
        const parentIds = new Set<TreeNodeId>();
    
        // Recursive function to find parent IDs for a given targetId
        const findParentIds = (nodes: TreeNode[], targetId: TreeNodeId, parentPath: TreeNodeId[] = []) => {
            for (const node of nodes) {
                if (node.id === targetId) {
                    parentPath.forEach(id => parentIds.add(id));
                    return true; // Target found
                }
                if (node.children) {
                    const found = findParentIds(node.children, targetId, [...parentPath, node.id]);
                    if (found) {
                        return true; // Stop searching after finding the target
                    }
                }
            }
            return false; // Target not found in this path
        };
    
        // Iterate over each targetId and find its parent path
        targetIds.forEach(targetId => {
            findParentIds(nodes, targetId);
        });
    
        // Convert Set to Array before returning
        return Array.from(parentIds);
    };

    // Recursive function to find parent ID for a given targetId
    const findParentId = (targetId: TreeNodeId): TreeNodeId | null => {
        const findInChildren = (node: TreeNode): TreeNodeId | null => {
            if (!node.children) return null;
            for (const child of node.children) {
                if (child.id === targetId) {
                    return node.id;
                }
                const found = findInChildren(child);
                if (found) {
                    return found; // Stop searching after finding the target
                }
            }
            return null; // Target not found in this path
        };
        return findInChildren(sceneTree);
    };

    function unionOfArrays<T>(array1: T[], array2: T[]): T[] {
        // Combine both arrays and create a Set to remove duplicates
        const unionSet = new Set([...array1, ...array2]);
    
        // Convert the Set back to an array
        return Array.from(unionSet);
    }

    useEffect(() => {

        if(selectedNodes === null) return

        if (sceneTree?.children) {
            const parentIds = findParentIdsForMultipleTargets(sceneTree.children, selectedNodes);
            setExpandedNodes(unionOfArrays(parentIds, expandedNodes));
        }
    }, [selectedNodes, sceneTree]); // Empty dependency array ensures this effect runs once on mount

    const handleExpandedItemsChange = (
        event: React.SyntheticEvent,
        itemIds: string[],
    ) => {
        setExpandedNodes(itemIds);
        event.stopPropagation();
    };

    const handleDragStart = (e: DragEvent<HTMLLIElement>, node: TreeNode) => {
        e.stopPropagation();


        if (selectedNodes && selectedNodes.some(id => id == node.id)) {
            setDraggedItemIds(selectedNodes);
        } else {        
            setDraggedItemIds([node.id]);
        }
    }
    
    const handleDragOver = (e: DragEvent<HTMLLIElement> | DragEvent<HTMLUListElement>, node: TreeNode) => {
        e.preventDefault();
        e.stopPropagation();
        if (draggedItemIds.every(id => id !== node.id)) setDraggedOverItem(node);
    }

    const handleDragLeave = (e: DragEvent<HTMLLIElement>, node: TreeNode) => {
        e.preventDefault();
        if (node.id !== draggedOverItem?.id) setDraggedOverItem(null);
    }

    const handleDragEnd = (e: DragEvent<HTMLLIElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDraggedItemIds([]);
        setDraggedOverItem(null);
    }

    const handleDrop = (e: DragEvent<HTMLLIElement> | DragEvent<HTMLUListElement>) => {
        e.stopPropagation()
        if (draggedItemIds.length === 0 || !draggedOverItem) return;

        if (draggedOverItem.type == "node") { 
            draggedItemIds.forEach(id => {
                globalThis.lys.reparentNode(Number(draggedOverItem.id),Number(id), true)
            })
        } else {
            const folderId : number = globalThis.lys.createNodeGenId("New folder")
            if (!folderId) return

            const draggedOverItemParentId : TreeNodeId = findParentId(draggedOverItem.id)
            if (!draggedOverItemParentId) return

            draggedItemIds.forEach(id => {
                globalThis.lys.reparentNode(folderId, Number(id), true)
            })
            globalThis.lys.reparentNode(folderId, Number(draggedOverItem.id), true)
            globalThis.lys.reparentNode(Number(draggedOverItemParentId), folderId, true)
        }
        
        setDraggedItemIds([])
        setDraggedOverItem(null);
    }

    return (
        <>
            <SimpleTreeView
 
                onSelectedItemsChange={handleNodesSelected}
                selectedItems={selectedNodes}
                multiSelect

                expansionTrigger="iconContainer"
                expandedItems={expandedNodes}
                onExpandedItemsChange={handleExpandedItemsChange}
                // onItemExpansionToggle={handleExpansionToggle}
                onDragOver={e => handleDragOver(e, sceneTree)}
                onDrop={handleDrop}     
                slots={{
                    expandIcon: BoxPlusIcon,
                    collapseIcon: BoxMinusIcon
                }}

                sx={{
                    ...dragStyle(sceneTree),
                    height: "100%",
                    flexGrow: 1,
                    maxWidth: 400,
                    overflow: "auto"
                }}
            >
                {sceneTree?.children?.map(renderTree)}
            </SimpleTreeView>

           
        </>
    );
}
