import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

// Define the context type
interface SelectedNodesContextType {
  selectedNodeIds: number[];
  setSelectedNodeIds: (ids: number[]) => void;
}

// Create context with undefined default value
const SelectedNodesContext = createContext<SelectedNodesContextType | undefined>(undefined);

interface SelectedNodesProviderProps {
  children: ReactNode;
}

// Create a provider component
export const SelectedNodesProvider = ({ children }: SelectedNodesProviderProps) => {
  const [selectedNodeIds, setSelectedNodeIds] = useState<number[]>([]);
  const [forceUpdate, setForceUpdate] = useState(0);

  // Helper function to compare arrays
  const arraysAreEqual = (arr1: number[], arr2: number[]) => {
    return (
      arr1.length === arr2.length &&
      arr1.every((value, index) => value === arr2[index])
    );
  };

  useEffect(() => {
    globalThis.updateSelectedNodeIds = () => setForceUpdate((n) => n + 1);

    return () => {
      delete globalThis.updateMaterialList;
    };
  }, []);

  // useEffect to retrieve selected node IDs from globalThis.lys when forceUpdateCount changes
  useEffect(() => {
    if (!globalThis.lys) return;

    const retrievedNodeIds = globalThis.lys.getSelectedNodeIds();
    // Avoid setting state if the new node IDs are the same as the current state
    if (retrievedNodeIds && Array.isArray(retrievedNodeIds) && !arraysAreEqual(retrievedNodeIds, selectedNodeIds)) {
      setSelectedNodeIds(retrievedNodeIds);
    }
  }, [forceUpdate]);

  // useEffect to retrieve selected node IDs from globalThis.lys when forceUpdateCount changes
  useEffect(() => {
    if (!globalThis.lys) return;

    const retrievedNodeIds = globalThis.lys.getSelectedNodeIds();

    // Avoid setting state if the new node IDs are the same as the current state
    if (!arraysAreEqual(retrievedNodeIds, selectedNodeIds)) {
      globalThis.lys.setSelectedNodeIds(selectedNodeIds);
    }
  }, [selectedNodeIds]);

  return (
    <SelectedNodesContext.Provider
      value={{ selectedNodeIds, setSelectedNodeIds }}
    >
      {children}
    </SelectedNodesContext.Provider>
  );
};

// Custom hook to use the SelectedNodesContext
export const useSelectedNodes = (): SelectedNodesContextType => {
  const context = useContext(SelectedNodesContext);
  if (!context) {
    throw new Error('useSelectedNodes must be used within a SelectedNodesProvider');
  }
  return context;
};
