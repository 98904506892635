import SceneIcon from '@mui/icons-material/Category';
import ChatIcon from '@mui/icons-material/Chat';
import MaterialIcon from '@mui/icons-material/SportsSoccer';
import { Paper } from "@mui/material";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { SyntheticEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import Chat from './Chat';
import MaterialProperties from "./MaterialProperties";
import ResizableContainer from "../ResizableContainer";
import SceneTree from './SceneTree';
import TransformationProperties from './TransformationProperties';
import { User } from '../../utils/user';
import { CameraAlt } from '@mui/icons-material';
import MaterialList from './MaterialList';
import { useMaterials } from '../MaterialsContext';
import AssetManager from '../asset-library/AssetManager';
import Badge from '@mui/material/Badge';
import useWebSocketChat from '../../utils/websocket-chat-hook';
import { TreeNode } from '../WebGLCanvas';
import { useSelectedNodes } from '../SelectedNodesContext';
import ImageIcon from '@mui/icons-material/AspectRatio';
import EnvironmentIcon from '@mui/icons-material/Language';
import CameraIcon from '@mui/icons-material/PhotoCameraOutlined';
import EnvironmentList from './EnvironmentList';
import EnvironmentProperties from './EnvironmentProperties';
import { useSceneTabMode, SceneTabMode } from '../SceneTabModeContext';
import CameraList from './CameraList';
import CameraProperties from './CameraProperties';

const breakPointHeight = 600;

export enum Mode {
    scene,
    materials,
    envs,
    cameras,
    images,
    renders,
    chat,
}

export type SceneDrawerProps = {
    user: User,
    sceneId: string,
    materialTypes: any,
    canEdit: boolean,
    sceneTree: TreeNode,
    height: number,
    
    drawerWidth: number,

    newRenderCounter: number,
    setNewRenderCounter: (count: number) => void

    newChatMessagesCounter: number,
    setNewChatMessagesCounter: Dispatch<SetStateAction<number>>
}

export default function SceneDrawer({ 
    sceneId, canEdit, drawerWidth,  
    sceneTree, height, materialTypes, user, 
    newRenderCounter, setNewRenderCounter,
    newChatMessagesCounter, setNewChatMessagesCounter
}: SceneDrawerProps) {

    const [language, setLanguage] = useState('');
    const { sceneTabMode, setSceneTabMode } = useSceneTabMode(); // Access setMode
    const [numberOfMessagesSeen, setNumberOfMessagesSeen] = useState(0);
    const { messages, sendMessage, error, isConnected, numberOfMessagesInHistory } = useWebSocketChat(sceneId, language);

    const { selectedNodeIds } = useSelectedNodes();

    const handleChange = (_event: SyntheticEvent, newTabIndex: SceneTabMode) => {
        if (sceneTabMode === SceneTabMode.renders && newTabIndex !== SceneTabMode.renders) {
            setNewRenderCounter(0);
        }
        if (sceneTabMode === SceneTabMode.chat && newTabIndex !== SceneTabMode.chat) {
            setNewChatMessagesCounter(0);
        }

        setSceneTabMode(newTabIndex);

        // Selecting a tab with a badge
        if (newTabIndex === SceneTabMode.renders) {
            setNewRenderCounter(0);  // Reset the counter
        }
        if (newTabIndex === SceneTabMode.chat) {
            setNewChatMessagesCounter(0); 
        }
    };

    useEffect(() => {
        setNumberOfMessagesSeen(n => Math.max(n, numberOfMessagesInHistory));
    }, [numberOfMessagesSeen, numberOfMessagesInHistory])

    useEffect(() => {
        if (sceneTabMode !== SceneTabMode.chat) {
            setNewChatMessagesCounter(messages.length - numberOfMessagesSeen);
        } else {
            setNumberOfMessagesSeen(messages.length);
        }
    }, [numberOfMessagesSeen, messages])

    const [detailTabIndex, setDetailTabIndex] = useState<SceneTabMode>(SceneTabMode.scene);

    const handleDetailTabChange = (_event: SyntheticEvent, mode: SceneTabMode) => {
        setDetailTabIndex(mode);
    };

    const { setSelectedMaterialIds } = useMaterials();  
    useEffect(() => {
        if (selectedNodeIds.length > 0) {
            const materialIds: number[] = selectedNodeIds.map((nodeId: number) => {
                const node = globalThis.lys.getNodeById(nodeId);
                if (node !== null) {
                    const material = node.getMaterial();
                    return material ? material.getId() : undefined;
                }
                return undefined;
            }).filter((id): id is number => id !== undefined); // Filter out undefined values
    
            setSelectedMaterialIds(materialIds);
        }
    }, [selectedNodeIds]);


    return (
        <Paper square={true} sx={{ height: "100%" }}>
            <Box
                width={drawerWidth}
                height={height}
                display="flex"
                sx={{ height: "100%" }}
                flexDirection="column"
                justifyContent="flex-start">

                {(height > breakPointHeight) &&
                    <Tabs variant="fullWidth" value={sceneTabMode.valueOf()} onChange={handleChange} aria-label="icon tabs example">
                        {canEdit && <Tab icon={<SceneIcon />} value={Mode.scene} label="Scene" aria-label="scene" sx={{ minWidth: 50, textTransform: 'none', }} />}
                        {canEdit && <Tab icon={<MaterialIcon />} value={Mode.materials} label="Materials" aria-label="materials" sx={{ minWidth: 50, textTransform: 'none', }} />}
                        {canEdit && <Tab icon={<EnvironmentIcon />} value={Mode.envs} label="Envs" aria-label="environments" sx={{ minWidth: 50, textTransform: 'none', }} />}
                        {canEdit && <Tab icon={<CameraIcon />} value={Mode.cameras} label="Cameras" aria-label="cameras" sx={{ minWidth: 50, textTransform: 'none', }} />}
                        {/* {canEdit && <Tab icon={<ImageIcon />} value={Mode.images} label="Images" aria-label="image" sx={{ minWidth: 50, textTransform: 'none', }} />} */}
                        {canEdit && (
                            <Tab
                                icon={
                                    <Badge badgeContent={newRenderCounter} color="primary">
                                        <CameraAlt />
                                    </Badge>
                                }
                                value={Mode.renders}
                                label="Renders"
                                aria-label="renders"
                                sx={{ minWidth: 50, textTransform: 'none' }}
                            />
                        )}
                        <Tab icon={
                            < Badge badgeContent={newChatMessagesCounter} color="primary">
                                <ChatIcon />
                            </Badge>
                        }
                            value={Mode.chat}
                            label="Chat"
                            aria-label="chat"
                            sx={{ minWidth: 50, textTransform: 'none', }} />
                    </Tabs>
                }

                {sceneTabMode === SceneTabMode.cameras && sceneId && (<ResizableContainer sx={{ height: "100%" }}>
                    <CameraList />
                    <CameraProperties />
                </ResizableContainer>)}

                {sceneTabMode === SceneTabMode.envs && sceneId && (<ResizableContainer sx={{ height: "100%" }}>
                    <EnvironmentList />
                    <EnvironmentProperties />
                </ResizableContainer>)}

                {sceneTabMode === SceneTabMode.materials && sceneId && (<ResizableContainer sx={{ height: "100%" }}>
                    <MaterialList />
                    <MaterialProperties
                        materialTypes={materialTypes} />
                </ResizableContainer>)}

                {sceneTabMode === SceneTabMode.chat && sceneId && (<ResizableContainer sx={{ height: "100%" }}>
                    <Chat
                        messages={messages} 
                        sendMessage={sendMessage} 
                        error={error} 
                        isConnected={isConnected}
                        sceneId={sceneId} 
                        user={user} 
                        cameraPosition='1,1,0.5' 
                        language={language} setLanguage={setLanguage} 
                        setNewChatMessagesCounter={setNewChatMessagesCounter} 
                    />
                </ResizableContainer>)}

                {sceneTabMode === SceneTabMode.renders && sceneId && (<ResizableContainer>
                    <AssetManager selectedPath={`home/Renders/${sceneId}.renders`} showFolders={false} recursive={true} filetypes={'.jpg'} sx={{ height: "100%" }} />
                </ResizableContainer>)}

                {sceneTabMode === SceneTabMode.scene && sceneId && (<ResizableContainer>
                    <SceneTree sceneTree={sceneTree} />
                    {selectedNodeIds.length > 0 &&
                        <>
                            <Tabs variant="fullWidth" value={detailTabIndex} onChange={handleDetailTabChange} aria-label="icon tabs example">

                                <Tab label="Position" sx={{ minWidth: 50, fontSize: 12, fontWeight: 400, textTransform: 'none' }} />
                                <Tab label="Material" sx={{ minWidth: 50, fontSize: 12, fontWeight: 400, textTransform: 'none' }} />
                                <Tab label="Properties" sx={{ minWidth: 50, fontSize: 12, fontWeight: 400, textTransform: 'none' }} />

                            </Tabs>

                            <Box style={{ width: '100%' }}>

                                {detailTabIndex == 0 && <TransformationProperties />}
                                {detailTabIndex == 1 && <MaterialProperties
                                    materialTypes={materialTypes} />}


                            </Box>
                        </>
                    }
                </ResizableContainer>)}

            </Box>
        </Paper>
    )
}
