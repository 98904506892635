import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { AnyParameter, useMaterials } from '../MaterialsContext';
import { useState, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import { useCallback } from 'react';

interface MaterialPropertiesProps {
    materialTypes: string[];
}
export default function MaterialProperties({ materialTypes }: MaterialPropertiesProps) {
    const {
        selectedMaterialProperties,
        setMaterialType,
        setMaterialParameter,
    } = useMaterials();

    const [name, setName] = useState(selectedMaterialProperties[0]?.name || "");

    const handleNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setName(e.target.value);
    };

    useEffect(() => {
        if (selectedMaterialProperties[0]) {
            setName(selectedMaterialProperties[0].name || "");
        }
    }, [selectedMaterialProperties]);

    const backgroundColor = (color: string) => {
        const s = { background: '#ff0000', border: '1px solid #C4C4C4' };
        s.background = color;
        return s;
    }

    function throttle(func: (...args: any) => void, limit: number) {
        let inThrottle: boolean;
        return function (...args: any) {
            if (!inThrottle) {
                func.apply(this, args);
                inThrottle = true;
                setTimeout(() => (inThrottle = false), limit);
            }
        };
    }

    // Inside your component
    const throttledSetMaterialParameter = useCallback(
        throttle((name, value) => {
            setMaterialParameter(name, value);
        }, 100),  // 200ms delay
        []
    );

    function paramToUI(entry: AnyParameter, index: number) {
        switch (entry.type) {
            case 'double':
                {
                    return (
                        <Grid container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            
                            sx={{ paddingBottom: 1, paddingRight: 1, paddingLeft: 1 }}
                            key={index}
                        >
                            <Grid xs="auto">
                                {entry.name}
                            </Grid>

    
                            <Grid xs sx={{ paddingLeft: 1, paddingRight: 1 }}>
                                <Slider
                                    size="small"
                                    style={{ width: '100%' }}
                                    value={entry.value * 50} // Display either internal or external value
                                    onChange={(_e, newValue: number) => {
                                        setMaterialParameter(entry.name, newValue / 50);
                                    }}
                                    aria-labelledby="input-slider"
                                    sx={{ MozUserSelect: "none", WebkitUserSelect: "none", msUserSelect: "none" }}
                                />
                            </Grid>
    
                            <Grid xs={4}>
                                <TextField
                                    margin="none"
                                    variant='outlined'
                                    type="number"
                                    size="small"
                                    fullWidth
                                        
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                        
                                    value={entry.value}
                              
                                    onChange={(e => { setMaterialParameter(entry.name, parseFloat(e.target.value)) })}
                                    onBlur={(e => setMaterialParameter(entry.name, parseFloat(e.target.value)))}
                                    inputProps={{
                                        step: 0.1,
                                        min: 0,
                                        max: 2,
                                        inputMode: 'decimal',
                                        type: 'number',
                                        'aria-labelledby': 'input-slider',
    
                                    }}
                                />
    
                                {/* <input
                                        type="color"
                                        value={entry.value}
                                        onChange={(e => handleParameterChange(e.target.value,index))}
                                    /> */}
                            </Grid>
                        </Grid>
                     
                    )
                }
            case 'color':
                {
                    const value = entry.value as string;
                    return (
                        <Grid container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ paddingBottom: 1, paddingRight: 1, paddingLeft: 1 }}
                            key={index}
                        >
                            <Grid key={entry.name} xs>
                                {entry.name}
                            </Grid>
                            <Grid xs={4} style={backgroundColor(value)} onClick={(e) => { (e.currentTarget.childNodes[0] as HTMLElement)?.focus(); (e.currentTarget.childNodes[0] as HTMLElement)?.click() }}>
                                <input
                                    style={{ opacity: 0 }}
                                    type="color"
                                    value={value}
                                    onInput={(e) => throttledSetMaterialParameter(entry.name, e.currentTarget.value)}
                                />
                            </Grid>
                        </Grid>
                     
                    )
                }
            case 'texture':
                {
                    return (
                        <Grid container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ paddingBottom: 1, paddingRight: 1, paddingLeft: 1 }}
                            key={index}
                        >
                            <Grid key={entry.name} xs>
                                {entry.name}
                            </Grid>
                            <Grid xs={4} style={backgroundColor(entry.value)} onClick={e => { (e.currentTarget.childNodes[0] as HTMLElement)?.focus(); (e.currentTarget.childNodes[0] as HTMLElement)?.click() }}>
                                <input
                                    style={{ opacity: 0 }}
                                    type="color"
                                    value={entry.value}
                                    onInput={(e => setMaterialParameter(entry.name, e.currentTarget.value))}
                                />
                            </Grid>
                        </Grid>
                     
                    )
    
                }
    
        }
    
    
    }
    
    return (

        <Box >
         
            {selectedMaterialProperties[0] &&
          
                <Grid container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ padding: 1 }}

                >
                    <Grid xs={2} sx={{ paddingBottom: 1 }}>
                        Name
                    </Grid>
                    <Grid xs={10} sx={{ paddingBottom: 1 }}>
                        <TextField
                            id="standard-basic"
                            value={name}
                            fullWidth
                            variant="outlined"
                            size="small"
                            disabled={!selectedMaterialProperties[0]}
                            hiddenLabel
                            InputLabelProps={selectedMaterialProperties[0] && { shrink: true }}
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                            onChange={handleNameChange}
                            onBlur={(e) => globalThis.lys.setMaterialName(selectedMaterialProperties[0].id, e.currentTarget.value, true)}
                            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => { if (e.key === 'Enter')  (e.target as HTMLInputElement).blur(); }}
                        />
                    </Grid>


                    <Grid xs={8}>
                        Type
                    </Grid>
                    <Grid xs={4}>

                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"

                            onChange={e => setMaterialType(e.target.value)}
                            size="small"
                            value={selectedMaterialProperties[0] && selectedMaterialProperties[0].type}
                            inputProps={{ 'aria-label': 'Without label' }}
                            style={{ width: '100%' }}
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}

                        >

                            {materialTypes &&
                                materialTypes.map(
                                    (name) =>
                                    (
                                        <MenuItem key={name} value={name}>{name}</MenuItem>
                                    )
                                )}


                        </Select>
                    </Grid>
                </Grid>
            }   
    
            {selectedMaterialProperties[0] && selectedMaterialProperties[0].parameters.map(
                (entry: AnyParameter, index: number) =>
                (
                    paramToUI(entry, index)
                )

            )}
        
        </Box>



    );
};
