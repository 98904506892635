import { createRef, forwardRef, useImperativeHandle } from "react";
import { StepImporter } from "../../opencascade/StepImporter"

export interface ModelFileImporter {
    openDialog: () => void;
}

export type ModelFileImporterProps = {
    onFileRead?: (buffer: ArrayBuffer, filename: string) => void;
}

const ModelFileImporter = forwardRef(({ onFileRead }: ModelFileImporterProps, ref) => {
    const inputRef = createRef<HTMLInputElement>();

    useImperativeHandle(ref, () => ({
        openDialog: () => {
            inputRef.current.click();
        }
    }))

    const onFileChange = (e) => {
        e.preventDefault();
        if (!!onFileRead) {
            const reader = new FileReader();
            const filename = e.target.files[0].name;
            reader.onload = (e: ProgressEvent<FileReader>) => onFileRead(e.target.result as ArrayBuffer, filename);
            reader.readAsArrayBuffer(e.target.files[0]);
        } else {
            loadModelFile(e.target.files[0]);
        }
    };

    return (
        <input type="file" onChange={onFileChange} ref={inputRef} style={{ display: 'none' }} />
    );
})

export function loadModelFile(file: File): void {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => { importModelFromEvent(file.name, event) };
    reader.readAsArrayBuffer(file);
}

export async function importModelFromEvent(filename: string, event: ProgressEvent<FileReader>): Promise<void> {
    await importModelFromBuffer(new Uint8Array(event.target.result as ArrayBufferLike), filename);
}

export async function importModelFromBuffer(buffer: Uint8Array | ArrayBuffer, filename: string): Promise<void> {
    if (buffer instanceof ArrayBuffer) {
        buffer = new Uint8Array(buffer);
    }

    if (filename.endsWith('.step') || filename.endsWith('.stp')) {
        buffer = await new StepImporter().importStep(buffer)
        filename = filename.replace(/\.[^/.]+$/, ".glb");
    } 

    const ptr = window.lys._malloc(buffer.byteLength);
    window.lys.HEAPU8.set(new Uint8Array(buffer), ptr);
    window.lys.importFileModel(filename, ptr, buffer.byteLength);
    window.lys._free(ptr);
}

export { ModelFileImporter };