import React, { useState, useEffect } from 'react';
import { SimpleTreeView } from '@mui/x-tree-view';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Box } from '@mui/material';
import { useDragItemContext } from '../DragItemContext';
import EnvironmentIcon from '@mui/icons-material/Language';

// Define EnvironmentMap interface
interface EnvironmentMap {
  id: number;
  name: string;
  thumbnailUrl: string;
}

const EnvironmentList = () => {
  const { setDraggedItem } = useDragItemContext();
  const [selectedEnvironment, setSelectedEnvironment] = useState<number>();
  const [environmentMaps, setEnvironmentMaps] = useState<EnvironmentMap[]>([]); 
  const [forceUpdate, setForceUpdate] = useState(0);

  const handleDragStart = (environmentMap: EnvironmentMap, event: React.DragEvent) => {
    setDraggedItem(environmentMap, "environmentMapList");
  };

  const handleNodesSelected = (event: React.SyntheticEvent, itemIds: string[] | string) => {
    const selectedId = Array.isArray(itemIds) ? Number(itemIds[0]) : Number(itemIds);
    
    if (!isNaN(selectedId)) {
      globalThis.lys.setActiveEnvironmentIndex(selectedId, true);
      setSelectedEnvironment(selectedId);
    }
  };

  useEffect(() => {
    globalThis.updateEnvironmentMaps = () => setForceUpdate((n) => n + 1);
    updateEnvironmentList();

    return () => {
      delete globalThis.updateEnvironmentMaps;
    };
  }, []);

  const updateEnvironmentList = () => {
    if (!globalThis.lys) return;

    const envIds: number[] = globalThis.lys.getAllEnvironmentIds();
    console.log(envIds);

    // Fetch environment names and thumbnails
    const newEnvironmentMaps: EnvironmentMap[] = envIds.map((id) => {
      const texture = globalThis.lys.getHdriTextureById(id);
        
      const name = texture?.getName(); // Fetch the name of the texture
      const thumbnailUrl = undefined;// = texture.getThumbnailUrl(); // Assuming there's a method to get thumbnail URL
      return {
        id,
        name,
        thumbnailUrl,
      };
    });

    setSelectedEnvironment(globalThis.lys.getActiveEnvironmentIndex());
    setEnvironmentMaps(newEnvironmentMaps);
  }

  useEffect(() => {
    updateEnvironmentList();
  }, [forceUpdate]);

  return (
    <div>
      <SimpleTreeView
        selectedItems={selectedEnvironment ? [selectedEnvironment.toString()] : []}
        onSelectedItemsChange={handleNodesSelected}
      >
        {environmentMaps.map((environmentMap: EnvironmentMap) => (
          <TreeItem
            draggable={true}
            onDragStart={(e) => handleDragStart(environmentMap, e)}
            key={environmentMap.id}
            itemId={environmentMap.id.toString()}

            slots={{
                endIcon: EnvironmentIcon,
              }}

            label={
              <div
                style={{
                  padding: '4px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {/* Environment Map Name */}
                <span style={{ flexGrow: 1 }}>
                  {environmentMap.name}
                </span>

               
              </div>
            }
          />
        ))}
      </SimpleTreeView>
    </div>
  );
};

export default EnvironmentList;
