import MenuIcon from '@mui/icons-material/MenuOutlined';
import { ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import React, { SyntheticEvent, useRef } from 'react';
import MenuButton from '../MenuButton';

import { isMobileOnly } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import { createAndGotoNewScene } from '../../utils/scene';
import { createTemporarySceneName } from '../../utils/user';
import { HOME_PATH } from '../../App';
import { ModelFileImporter } from './ModelFileImporter';

export type MainMenuProps = {
    canEdit: boolean,
    setShowShareDialog: (show: boolean) => void
}

export default function MainMenu({ canEdit, setShowShareDialog }: MainMenuProps) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: SyntheticEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const fileImporter = useRef<ModelFileImporter>();

    let navigate = useNavigate();

    const handleClose = (e: SyntheticEvent) => {
        if (e.currentTarget.id == "import") {
            fileImporter.current.openDialog();
        }
        else if (e.currentTarget.id == "share") {
            setShowShareDialog(true);
        }
        else if (e.currentTarget.id == "clear") {
            window.lys._emptyScene();
        }
        else if (e.currentTarget.id == "new") {
            createAndGotoNewScene(createTemporarySceneName(), navigate);
        }
        else if (e.currentTarget.id == "files") {
            navigate(HOME_PATH);
        }
        else if (e.currentTarget.id != "basic-button") {
            window.lys.importNamedModel(e.currentTarget.id);
        }

        setAnchorEl(null);
    };

    return (
        <div>
            <ModelFileImporter ref={fileImporter} />
            <MenuButton
                icon={MenuIcon}
                id="basic-button"
                name="Menu"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={open ? handleClose : handleClick} 
            />

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transitionDuration={0}

                anchorOrigin={

                    isMobileOnly ?
                        {
                            vertical: 'top',
                            horizontal: 'left',
                        }
                        :
                        {
                            vertical: 'bottom',
                            horizontal: 'left',
                        }

                }

                transformOrigin={
                    isMobileOnly ?
                        {
                            vertical: 'bottom',
                            horizontal: 'left',
                        }
                        :
                        {
                            vertical: 'top',
                            horizontal: 'left',
                        }
                }

                PaperProps={{
                    style: {
                        width: 200,
                    },
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose} id="files">
                    <ListItemText>Back to Files</ListItemText>
                    <Typography variant="body2" color="text.secondary">
                        CTRL+N
                    </Typography>
                </MenuItem>

                {canEdit && <div>
                    <MenuItem onClick={handleClose} id="clear">
                        <ListItemText>Clear Scene</ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            CTRL+C
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClose} id="new">
                        <ListItemText>New Scene</ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            CTRL+E
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClose} id="import">
                        <ListItemText>Import</ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            CTRL+O
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClose} id="share">
                        <ListItemText>Share</ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            CTRL+S
                        </Typography>
                    </MenuItem>

                    <MenuItem onClick={handleClose} id="Cube">
                        <ListItemText>Cube</ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            CTRL+1
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClose} id="Sphere">
                        <ListItemText>Sphere</ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            CTRL+2
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClose} id="Cylinder">
                        <ListItemText>Cylinder</ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            CTRL+3
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClose} id="Disc">
                        <ListItemText>Disc</ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            CTRL+4
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClose} id="Plane">
                        <ListItemText>Plane</ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            CTRL+5
                        </Typography>
                    </MenuItem>
                </div>}

            </Menu>
        </div>


    )
}
