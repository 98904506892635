import React from 'react';
import { Box } from '@mui/system';

const CameraProperties: React.FC = () => {
  return (
    <Box sx={{m:1}}>
     
      </Box>
  );
}

export default CameraProperties;
