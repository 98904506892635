import { RichTreeView } from "@mui/x-tree-view";
import { useEffect, useState } from "react";
import * as FileApi from "../../services/file-api"

type FileTreeProps = {
  folderTree: FileApi.FSEntry[],
  selectedPath: string
  onSelectNode: (id: string) => Promise<void>
}

export default function FolderTree({ folderTree, selectedPath, onSelectNode }: FileTreeProps) {
  const [selectedTreeFolder, setSelectedTreeFolder] = useState<string>("");
  const [hasInitiallyExpanded, setHasInitiallyExpanded] = useState(false)
  const [expandedTreeFolders, setExpandedTreeFolders] = useState<string[]>([]);

  useEffect(() => {
    if (hasInitiallyExpanded || folderTree.length == 0) return;
    const level1 = folderTree.map((d: FileApi.FSEntry) => d.id);
    const level2 = folderTree.flatMap((d: FileApi.FSEntry) => d.children.map((c: FileApi.FSEntry) => c.id));
    const firstTwoLevels = level1.concat(level2);
    setExpandedTreeFolders(firstTwoLevels);
    setHasInitiallyExpanded(true);
  }, [folderTree])

  useEffect(() => {
    setSelectedTreeFolder(selectedPath);
  }, [selectedPath])

  // Helper function to find parent IDs for a given node
  const findParentIds = (nodes: any[], targetId: string, parentPath = []) => {
    for (const node of nodes) {
      if (node.id === targetId) {
        return parentPath;
      }
      if (node.children) {
        const path = findParentIds(node.children, targetId, [
          ...parentPath,
          node.id,
        ]);
        if (path) {
          return path;
        }
      }
    }
    return "";
  };

  const handleClickOnTreeViewItem = (_event, id) => {
    setSelectedTreeFolder(id);
    const newIds = Array.from(
      new Set([
        ...expandedTreeFolders,
        ...findParentIds(folderTree, id, []),
      ]),
    );
    setExpandedTreeFolders(newIds);
    onSelectNode(id)
  }

  const handleExpandedItemsChange = (
    event: React.SyntheticEvent,
    itemIds: string[],
  ) => {
    setExpandedTreeFolders(itemIds);
    event.stopPropagation();
  };

  const handleTreeExpansionToggle = (
    event: React.SyntheticEvent,
    itemId: string,
  ) => {
    if (selectedTreeFolder != itemId) handleClickOnTreeViewItem(event, itemId);
  };

  return (
    <RichTreeView
      items={folderTree}
      onSelectedItemsChange={handleClickOnTreeViewItem}
      selectedItems={[selectedTreeFolder]}
      expansionTrigger="iconContainer"
      expandedItems={expandedTreeFolders}
      defaultExpandedItems={expandedTreeFolders}
      onExpandedItemsChange={handleExpandedItemsChange}
      onItemExpansionToggle={handleTreeExpansionToggle}
    />
  )
}