import React, { useState } from 'react';
import { SimpleTreeView } from '@mui/x-tree-view';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Menu, MenuItem, Box } from '@mui/material';
import { useMaterials } from '../MaterialsContext';  
import { useDragItemContext } from '../DragItemContext'; 

// Define Material interface
interface Material {
  id: number;
  name: string;
  type: string;
  baseColor: { x: number; y: number; z: number };
}

const MaterialList = () => {
  const { materials, selectedMaterialIds, setSelectedMaterialIds } = useMaterials(); 
  const { setDraggedItem } = useDragItemContext();

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
    materialId: number | null;
  } | null>(null);

  // Handle right-click (context menu)
  const handleContextMenu = (event: React.MouseEvent, materialId: number) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY,
      materialId: materialId,
    });
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  // Function to create a Blob from canvas data
  function createSolidColorImageBlob(r: number, g: number, b: number, width = 128, height = 128) {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    // Set the fill style to the RGB color
    ctx.fillStyle = `rgb(${Math.floor(r)}, ${Math.floor(g)}, ${Math.floor(b)})`;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Convert the canvas to a data URL (JPEG)
    const dataUrl = canvas.toDataURL('image/jpeg');

    // Convert the data URL to a Blob
    const byteString = atob(dataUrl.split(',')[1]);
    const mimeType = 'image/jpeg';
    const byteArrays = [];

    for (let i = 0; i < byteString.length; i += 512) {
      const slice = byteString.slice(i, i + 512);

      const byteNumbers = new Array(slice.length);
      for (let j = 0; j < slice.length; j++) {
        byteNumbers[j] = slice.charCodeAt(j);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType });
  }

  const handleMerge = async () => {

    const lastMatId = selectedMaterialIds[0];
    const lastMaterial = globalThis.lys.getMaterialById(lastMatId);

    const allNodes: number[] = globalThis.lys.getAllNodeIds();
    allNodes.forEach((nodeId) => {
      const node = globalThis.lys.getNodeById(nodeId);
      if (node) {
        const mat = node.getMaterial();
        if (mat && selectedMaterialIds.includes(mat.getId())) {
          globalThis.lys.setMaterial(nodeId, lastMatId, true);
        }
      }
    });

    selectedMaterialIds.forEach((matId) => {
      if (matId != lastMatId) globalThis.lys.deleteMaterialById(matId,true);
    });
    handleClose();  // Close the menu after action is executed
  };

  const canSaveMaterialToLibrary = (): boolean => {
    if (contextMenu !== null) {
      const mat = globalThis.lys.getMaterialById(contextMenu.materialId);
      return mat?.getName() && mat.getName().trim().length > 0;
    }
    return false;
  }

  const handleSaveMaterialToLibrary = () => {
    if (contextMenu?.materialId !== null) {
      const mat = globalThis.lys.getMaterialById(contextMenu.materialId);

      if (!mat) {
        console.warn("Material " + contextMenu.materialId + " not found during save to library");
        handleClose();
        return;
      }

      const form = new FormData();
      const materialBlob = new Blob([mat.toJSONString()], { type: 'application/json' });
      form.set("material", materialBlob);
            
      const colorImageBlob = createSolidColorImageBlob(mat.baseColor.x*255, mat.baseColor.y*255, mat.baseColor.z*255);
      form.set("thumbnail", colorImageBlob); // Use the solid color image as the thumbnail


      fetch("/api/materials/", {
        method: "POST",
        body: form,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to save material');
          }
          return response.text();
        })
        .then((result) => {
          console.log('Material saved:', result);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    handleClose();  // Close the menu after action is executed
  };

  // Handle material selection updates
  const handleNodesSelected = (event: React.MouseEvent, selectedIds: string[]) => {
    const selectedIdsAsNumbers = selectedIds.map(Number);  // Convert string IDs to numbers
    setSelectedMaterialIds(selectedIdsAsNumbers);
  };

  // Helper function to convert the baseColor (x, y, z) into an RGB string
  const colorToRGB = (color: { x: number; y: number; z: number }): string => {
    const r = Math.round(color.x * 255);
    const g = Math.round(color.y * 255);
    const b = Math.round(color.z * 255);
    return `rgb(${r}, ${g}, ${b})`;
  };

  const handleDragStart = (material, event) => {
    setDraggedItem(material,"materialList");  // Pass the event to set custom data in dataTransfer
  };

  return (
    <div>
      <SimpleTreeView
        multiSelect
        selectedItems={selectedMaterialIds.map(String)}  // Convert number IDs to strings for selection
        onSelectedItemsChange={handleNodesSelected}  // Update selection when items are selected
      >
        {materials.map((material: Material) => (
         <TreeItem

         draggable={true}

         onDragStart={(e) => handleDragStart(material,e)}  // Set dragged file on drag start

         key={material.id}
         itemId={material.id.toString()}
   
         // Place the Box in the endIcon slot
         slots={{
           endIcon: () => (
             <Box
               component="span"
               sx={{
                 width: 12,
                 height: 12,
                 borderRadius: '50%',
                 backgroundColor: colorToRGB(material.baseColor),
                 border: '1px solid grey', // Add border for the outline
               }}
             />
           ),
         }}
   
         label={
           <div
             onContextMenu={(event) => handleContextMenu(event, material.id)}
             style={{
               padding: '4px',
               display: 'flex',
               alignItems: 'center',
             }}
           >
             {/* Material Name - Flex Grow to take up space */}
             <span style={{ flexGrow: 1 }}>
               {material.name}
             </span>
   
             {/* Material Type - Aligned to Far Right */}
             <span>
               {material.type}
             </span>
           </div>
            }
          />
        ))}
      </SimpleTreeView>

      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleSaveMaterialToLibrary} disabled={!canSaveMaterialToLibrary()}>Save to Library</MenuItem>
        {selectedMaterialIds.length>1 && <MenuItem onClick={handleMerge}>Merge with {materials.find(material => material.id === selectedMaterialIds[0]).name}</MenuItem>}
      </Menu>
    </div>
  );
};

export default MaterialList;