import LockClosedIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useState } from "react";
import { useSelectedNodes } from '../SelectedNodesContext';
import NumberInput from '../NumberInput';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FButton from '../FButton';
import { useRef } from 'react';
import { Box } from '@mui/system';


export default function TransformationProperties() {

    const [translationX, setTranslationX] = useState<number |undefined>(undefined);
    const [translationY, setTranslationY] =  useState<number |undefined>(undefined);
    const [translationZ, setTranslationZ] =  useState<number |undefined>(undefined);

    const [rotationX, setRotationX] = useState<number |undefined>(undefined);
    const [rotationY, setRotationY] =  useState<number |undefined>(undefined);
    const [rotationZ, setRotationZ] =  useState<number |undefined>(undefined);

    const [scaleX, setScaleX] =  useState<number |undefined>(undefined);
    const [scaleY, setScaleY] =  useState<number |undefined>(undefined);
    const [scaleZ, setScaleZ] =  useState<number |undefined>(undefined);

    const [scale, setScale] =  useState<number |undefined>(undefined);

    const [lockScale, setLockScale] = useState(true);

    const [forceUpdate, setForceUpdate] = useState(0);

 

    const { selectedNodeIds, setSelectedNodeIds } = useSelectedNodes();

    const [transformSpace, setTransformSpace] = useState<'local' | 'global'>('local');
    const [transformType, setTransformType] = useState<'relative' | 'absolute'>('relative');

    const nodeId = useRef<number | null>(null);

    const valuesChanged = (last: Number[], ...values: Number[]) => {
        if (last.length != values.length) return true;
        for (let i = 0; i < last.length; i++) {
            if (last[i] != values[i]) return true;
        }
        return false;
    }


    useEffect(() => {

        if (!nodeId.current) return;
        if ( translationX == undefined || translationY == undefined || translationZ == undefined || rotationX == undefined || rotationY == undefined || rotationZ == undefined || scaleX == undefined || scaleY == undefined || scaleZ == undefined) return;
        if (scaleX == 0 || scaleY == 0 || scaleZ == 0) return;

        const currentValues = globalThis.lys.getTransformRotationScale(nodeId.current, transformSpace == 'global');

        if (globalThis.lys && valuesChanged(currentValues,translationX, translationY, translationZ, rotationX, rotationY, rotationZ, scaleX, scaleY, scaleZ))
            globalThis.lys.setTranslateRotationScale(nodeId.current,
                translationX,
                translationY,
                translationZ,
                rotationX,
                rotationY,
                rotationZ,
                scaleX,
                scaleY,
                scaleZ,
                transformSpace == 'global',
                true
            );

    }, [translationX, translationY, translationZ, rotationX, rotationY, rotationZ, scaleX, scaleY, scaleZ]);


    useEffect(() => {

        if (!nodeId.current) return;
        if ( translationX == undefined || translationY == undefined || translationZ == undefined || rotationX == undefined || rotationY == undefined || rotationZ == undefined || scaleX == undefined || scaleY == undefined || scaleZ == undefined) return;
        if (scale == 0) return;

        const average = ((scaleX) + (scaleY) + (scaleZ)) / 3.0;
        const mult = (scale) / average;

        const currentValues = globalThis.lys.getTransformRotationScale(nodeId.current, transformSpace == 'global');

        if (globalThis.lys && valuesChanged(currentValues,translationX, translationY, translationZ, rotationX, rotationY, rotationZ, scaleX*mult, scaleY*mult, scaleZ*mult))
            globalThis.lys.setTranslateRotationScale(nodeId.current,
                translationX,
                translationY,
                translationZ,
                rotationX,
                rotationY,
                rotationZ,
                scaleX * mult,
                scaleY * mult,
                scaleZ * mult, 
                transformSpace == 'global',
                true,
            );

    }, [scale]);

    const handleReset = () => {
        setTranslationX(0);
        setTranslationY(0);
        setTranslationZ(0);

        setRotationX(0);
        setRotationY(0);
        setRotationZ(0);

        setScaleX(1);
        setScaleY(1);
        setScaleZ(1);
    }

    const getData = () => {
        if (!nodeId.current) return;

        const currentValues = globalThis.lys.getTransformRotationScale(nodeId.current, transformSpace == 'global');

        // Update state with the returned values, converted to strings
        setTranslationX(currentValues[0]);
        setTranslationY(currentValues[1]);
        setTranslationZ(currentValues[2]);

        setRotationX(currentValues[3]);
        setRotationY(currentValues[4]);
        setRotationZ(currentValues[5]);

        setScaleX(currentValues[6]);
        setScaleY(currentValues[7]);
        setScaleZ(currentValues[8]);

        setScale((currentValues[6] + currentValues[7] + currentValues[8]) / 3.0);

    }

    useEffect(() => {
        if (nodeId.current != selectedNodeIds[0]) nodeId.current = selectedNodeIds[0];
    
        getData();

    }, [selectedNodeIds]);


    useEffect(() => {

        getData();

    }, [forceUpdate, transformSpace]);

    useEffect(() => {
        globalThis.updateSelectedTransforms = () => setForceUpdate((n) => n + 1);
        globalThis.updateSelectedTransforms();
    }, []);

    return (
        <>


            <Grid container spacing={0} padding={1} columns={12} alignItems="center" justifyContent="center" rowSpacing={1} columnSpacing={1}

            >



                <Grid item xs={3}><Typography align="left">Translation:</Typography></Grid>
                <Grid item xs={3}><NumberInput disabled={selectedNodeIds.length != 1} value={translationX} setValue={setTranslationX} label="X" /></Grid>
                <Grid item xs={3}><NumberInput disabled={selectedNodeIds.length != 1} value={translationY} setValue={setTranslationY} label="Y" /></Grid>
                <Grid item xs={3}><NumberInput disabled={selectedNodeIds.length != 1} value={translationZ} setValue={setTranslationZ} label="Z" /></Grid>

                <Grid item xs={3}><Typography align="left">Rotation:</Typography></Grid>
                <Grid item xs={3}><NumberInput disabled={selectedNodeIds.length != 1} adornment="°" step={15} value={rotationX} setValue={setRotationX} label="X" /></Grid>
                <Grid item xs={3}><NumberInput disabled={selectedNodeIds.length != 1} adornment="°" step={15} value={rotationY} setValue={setRotationY} label="Y" /></Grid>
                <Grid item xs={3}><NumberInput disabled={selectedNodeIds.length != 1} adornment="°" step={15} value={rotationZ} setValue={setRotationZ} label="Z" /></Grid>

                <Grid item xs={3}><Typography align="left">Scale: {
                    lockScale ? 
                        <LockClosedIcon fontSize='small' onClick={() => setLockScale(false)} /> : 
                        <LockOpenIcon fontSize='small' onClick={() => setLockScale(true)} />}  
                </Typography>
                </Grid>

                {lockScale &&
                    <>
                        <Grid item xs={3}><NumberInput disabled={selectedNodeIds.length != 1} value={scale} setValue={setScale} label="S" /></Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={3}></Grid>
                    </>
                }
                {!lockScale &&
                    <>
                        <Grid item xs={3}><NumberInput disabled={selectedNodeIds.length != 1} value={scaleX} setValue={setScaleX} label="X" /></Grid>
                        <Grid item xs={3}><NumberInput disabled={selectedNodeIds.length != 1} value={scaleY} setValue={setScaleY} label="Y" /></Grid>
                        <Grid item xs={3}><NumberInput disabled={selectedNodeIds.length != 1} value={scaleZ} setValue={setScaleZ} label="Z" /></Grid>
                    </>
                }
            </Grid>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ m: 1 }}
            >


                <ToggleButtonGroup
                    value={transformSpace}
                    exclusive
                    onChange={(e, newValue) => {
                        if (newValue !== null) {
                            setTransformSpace(newValue);
                        }
                    }}
                    aria-label="transform space"
                    sx={{
                        '& .MuiToggleButtonGroup-grouped': {
                            borderRadius: 0,
                            '&:first-of-type': {
                                borderTopLeftRadius: '16px',
                                borderBottomLeftRadius: '16px',
                            },
                            '&:last-of-type': {
                                borderTopRightRadius: '16px',
                                borderBottomRightRadius: '16px',
                            },
                        },
                    }}
                >
                    <ToggleButton value="local" style={{ textTransform: 'none', fontSize: '0.875rem', minHeight: '32px', padding: '4px 12px' }}>
                        Local
                    </ToggleButton>
                    <ToggleButton value="global" style={{ textTransform: 'none', fontSize: '0.875rem', minHeight: '32px', padding: '4px 12px' }}>
                        Global
                    </ToggleButton>
                </ToggleButtonGroup>

                <FButton onClick={handleReset} color="secondary">Reset</FButton>

            </Box>



        </>
    );
}
