import { ArrowCircleUp, Bolt, CameraAlt, CameraOutlined, CenterFocusStrongOutlined, FullscreenOutlined, HeightOutlined, MenuBookOutlined, ModeEditOutline, OpenWithOutlined, ReplayOutlined, SignalCellular0Bar, WidgetsOutlined } from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import ActiveUsers, { UserSession } from './ActiveUsers';
import FButton from '../FButton';
import MainMenu from './MainMenu';
import MenuButton from '../MenuButton';
import PrefabMenu from './PrefabMenu';
import ShareDialog from '../share/ShareDialog';
import UserAvatar from './UserAvatar';
import { isAuthenticatedUser, User } from '../../utils/user';
import { ModelFileImporter } from './ModelFileImporter';
import { Typography } from '@mui/material';

export type RenderMode = "Wire" | "Fast" | "Photo";

export type MenuAppBarProps = {
    onFullScreen: { enter: () => void, exit: () => void },
    isfullScreen: boolean,
    onIconClick: (id: string) => void,
    onCenterAndFitClick: (b: boolean) => void,
    onRenderClick: () => void,
    onRenderModeClick: (mode: RenderMode) => void,
    sceneId: string,
    sceneName: string,
    onChangeSceneName: (name: string) => void,
    onShowLibraryDrawer: (open: boolean) => void,
    onShowSceneDrawer: (b: boolean) => void,
    libraryDrawerOpen: boolean,
    sceneDrawerOpen: boolean,
    activeUsersList: UserSession[],
    user: User,
    onThemeModeChange: (mode: string) => void,
    canEdit: boolean,
}

export default function MenuAppBar
    ({
        onFullScreen,
        isfullScreen,
        onIconClick,
        onCenterAndFitClick,
        onRenderClick,
        onRenderModeClick,
        sceneId,
        sceneName,
        onChangeSceneName,
        onShowLibraryDrawer,
        onShowSceneDrawer,
        libraryDrawerOpen,
        sceneDrawerOpen,
        activeUsersList,
        user,
        onThemeModeChange,
        canEdit,
    }: MenuAppBarProps) {

    const [selectedButton, setSelectedButton] = useState("Tumble");
    const [renderMode, setRenderMode] = useState<RenderMode>("Photo");
    const [showShareDialog, setShowShareDialog] = useState(false);
    const [name, setName] = useState("");
    const modelImporter = useRef<typeof ModelFileImporter>();

    useEffect(() => {
        setName(sceneName)
    }, [sceneName])

    const centerAndFitClick = (_e: React.SyntheticEvent) => {
        onCenterAndFitClick(true);
    }

    const renderModeClick = (e: React.SyntheticEvent) => {
        let newMode: RenderMode = e.currentTarget.id as RenderMode;
        if (newMode == renderMode) {
            switch (e.currentTarget.id) {
                case 'Fast':
                    newMode = "Photo";
                    break;
                case 'Photo':
                    newMode = 'Fast';
                    break;
                case 'Wire':
                    newMode = 'Fast';
                    break;
            }
        }

        onRenderModeClick(newMode);
        setRenderMode(newMode);
    }

    const renderClick = (_e: React.SyntheticEvent) => {
        onRenderClick()
    }

    const buttonGroupClick = (e: React.SyntheticEvent) => {
        onIconClick(e.currentTarget.id);
        setSelectedButton(e.currentTarget.id);
    }

    const handleSceneNameBlur = () => {
        if (name.trim() !== '') {
            onChangeSceneName(name);            
        }
    };

    const handleKeyDownSceneName = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.currentTarget.blur(); // Remove focus when Enter is pressed
        }
    };

    const handleShare = (_event: MouseEvent) => {
        setShowShareDialog(!showShareDialog);
    }

    return (
        <>
            <AppBar enableColorOnDark color={"neutralbackground" as any} position={"fixed"} sx={isMobileOnly ? { top: 'auto', bottom: 0, paddingBottom: "25px" } : {}} elevation={0}>
                <Toolbar disableGutters={isMobileOnly ? undefined : true} variant="dense" >

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: "100%",
                            alignItems: "center"
                        }}
                    >

                        <MainMenu canEdit={canEdit} setShowShareDialog={setShowShareDialog} />

                        {canEdit && <MenuButton name="Library" icon={MenuBookOutlined}
                            onClick={() => onShowLibraryDrawer(!libraryDrawerOpen)} active={libraryDrawerOpen}
                        />}
                        {canEdit && <MenuButton name="Scene" icon={WidgetsOutlined}
                            onClick={() => onShowSceneDrawer(!sceneDrawerOpen)} active={sceneDrawerOpen}
                        />}

                        {!isMobileOnly && <Divider orientation="vertical" variant="middle" flexItem sx={{ marginLeft: 1, marginRight: 1, borderColor: '#777777' }} />}
                        {canEdit && <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' } }}>
                            <ModelFileImporter ref={modelImporter} />
                            <MenuButton name="Import" icon={ArrowCircleUp} onClick={() => { (modelImporter.current as any).openDialog(); }} />
                            <PrefabMenu />
                        </Box>}

                        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' } }}>
                            <MenuButton name={isfullScreen ? "Window" : "Fullscreen"} onClick={isfullScreen ? onFullScreen.exit : onFullScreen.enter} icon={FullscreenOutlined} />
                        </Box>

                        <MenuButton name="Center" shortcut="C" icon={CenterFocusStrongOutlined} onClick={centerAndFitClick} />
                  
                        {canEdit && <React.Fragment>
                            {!isMobileOnly && <Divider orientation="vertical" variant="middle" flexItem sx={{ display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' }, marginLeft: 1, marginRight: 1, borderColor: '#777777' }} />}                  
                            <Box sx={{ display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' } }}>
                                <MenuButton name="Wire" icon={SignalCellular0Bar} onClick={renderModeClick} active={renderMode} />
                            </Box>

                            <Box sx={{ display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' } }}>
                                <MenuButton name="Fast" icon={Bolt} onClick={renderModeClick} active={renderMode} />
                            </Box>
                        
                            <MenuButton name="Photo" icon={CameraOutlined} onClick={renderModeClick} active={renderMode} />
                        </React.Fragment>}

                        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' } }}>
                            {!isMobileOnly && <Divider orientation="vertical" variant="middle" flexItem sx={{ marginLeft: 1, marginRight: 1, borderColor: '#777777' }} />}
                            <MenuButton name="Select" shortcut="SHIFT + LEFT MOUSE BUTTON" icon={ModeEditOutline} onClick={buttonGroupClick} active={selectedButton} />
                            <MenuButton name="Tumble" shortcut="SHIFT + ALT+LEFT MOUSE BUTTON" icon={ReplayOutlined} onClick={buttonGroupClick} active={selectedButton} />
                            <MenuButton name="Pan" shortcut="SHIFT + CTRL + LEFT MOUSE BUTTON" icon={OpenWithOutlined} onClick={buttonGroupClick} active={selectedButton} />
                            <MenuButton name="Dolly" shortcut="ALT + LEFT MOUSE BUTTON" icon={HeightOutlined} onClick={buttonGroupClick} active={selectedButton} />
                            {/*<MenuButton name="Zoom" shortcut="CTRL + LEFT MOUSE BUTTON"  icon={ZoomInOutlined} onClick={buttonGroupClick} active={selectedButton}/>*/}
                        </Box>
                        <Divider orientation="vertical" variant="middle" flexItem sx={{ marginLeft: 1, marginRight: 1, borderColor: '#777777' }} />
                        {canEdit && <MenuButton name="Render" icon={CameraAlt} onClick={renderClick} />}

                        {!isMobileOnly && canEdit ?
                            (<InputBase
                                sx={{
                                    flex: 2,
                                    color: '#999999',
                                    fontSize: 14,
                                    textAlign: 'center',
                                    align: 'center',
                                    '& input': { textAlign: "center" },
                                    marginLeft: 0,
                                    display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' }
                                }}
                        
                                onBlur={handleSceneNameBlur}
                                onKeyDown={handleKeyDownSceneName}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Untitled"
                                value={name}
                            />) : (<Typography variant="h6" sx={{ flex: 2, textAlign: 'center' }}>{name}</Typography>)
                        }                    

                        <ActiveUsers activeUsersList={activeUsersList} />

                        {canEdit && <FButton
                            startIcon={<GroupIcon />}
                            sx={{
                                marginRight: 1,
                                display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' }
                            }}
                            onClick={handleShare} >
                            Share
                        </FButton>}

                        {isAuthenticatedUser(user) &&
                            <ShareDialog user={user} sceneId={sceneId} open={showShareDialog} onClose={() => setShowShareDialog(false)} />}
                        <UserAvatar user={user} onThemeModeChange={onThemeModeChange} />
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    )
}
